/** @todo: this file contains ids and stuff that we use for the Eurotier 2024 */
export const fakes = {
  orgIds: {
    gb: '8a1a5b0d-a840-4e0a-bb96-4807fb12a169',
    hag: '1960acc6-560f-4a75-ad7a-056d66b8ce71',
    age: 'f1c06947-2bb8-41b3-835b-58425abc1ea4',
  },
  modemIds: {
    gb: [
      'XMBGY'
    ],
    hag: [
      'ADCXW'
    ],
    age: [
      'CYGNC',
      'QROTC',
    ],
    /**
     * These modems / machines will be listed in all orgs
     * (actually, not - check the code ;-) )
     */
    allOrgs: [
      'WEWHC'
    ],
  },
  users: {
    vertrieb: '59392436-89ca-498c-837e-6962fd2ff92b',
    andreas: 'da5f0d75-333f-475e-9329-2ee703e5c201',
    heiner: '18fbbd71-a61a-4f9c-8772-7cc533c8a5a1',
    mdrosdek: '2a6a3db1-3cf0-492a-98e7-4042b2346205',
    steffen: 'e3c4e802-50a1-7084-969b-5d657b5fa0bf',
    jonathan: 'c64a3b70-1c2f-40d1-8858-258f42ac9c83',
    hannes_dev: '368f65fb-7fee-44cd-b3f3-1528eee8b980',
    hannes_prod: '6304d8c2-8031-70d7-c8bc-107d41f3e145'
  },
  roles: {
    rw: [] as string[]
  },
}
fakes.roles.rw.push(
  fakes.users.andreas, fakes.users.heiner, fakes.users.mdrosdek,
  fakes.users.steffen, fakes.users.jonathan,
  fakes.users.hannes_dev, fakes.users.hannes_prod)
